body {
  margin: 0;
  font-family: Quicksand ,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --amplify-primary-color: #f39200;
  --amplify-secondary-color: #e8c89b;
  --amplify-colors-background-info: #5F1C73;
  --amplify-font-family: Quicksand, sans-serif;
}
